<template>
  <div class="businessList">
    <div class="panel demoWrapper">
      <div class="basic-info">
        <div class="basic-info-top">Basic info</div>
        <div>
          <el-form
            ref="formLabelAlign"
            :model="formLabelAlign"
            label-position="right"
            label-width="180px"
            :inline="true"
            :rules="rules1"
          >
            <el-form-item label="Enterprise Number:" prop="Enterprise">
              <font v-text="formLabelAlign.id" />
            </el-form-item>

            <el-form-item label="Basic Info Audit Status:">
              <font v-if="formLabelAlign.baseInfoAuditState == 1">
                <i class="el-icon-success" style="color: #40c740" />Passed
              </font>
              <font v-else-if="formLabelAlign.baseInfoAuditState == 0">
                <i class="el-icon-time" style="color: black" />Waiting Audit
              </font>
              <font v-else>
                <i class="el-icon-error" style="color: #fa5050" />Rejected
              </font>
            </el-form-item>

            <el-form-item label="Enterprise Flag:">
              <font>General Enterprise</font>
            </el-form-item>

            <el-form-item label="Enterprise Name:" prop="enterpriseName">
              <el-input
                v-model.trim="formLabelAlign.enterpriseName"
                type="text"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Enterprise Mail:" prop="enterpriseEmail">
              <el-input
                v-model.trim="formLabelAlign.enterpriseEmail"
                type="text"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Enterprise Phone:" prop="enterprisePhone">
              <el-input
                v-model.trim="formLabelAlign.enterprisePhone"
                type="text"
                oninput="value=value.replace(/[^\d]/g,'')"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item
              label="License Type:"
              prop="licenseType"
              class="typeRight"
            >
              <el-select
                v-model.trim="formLabelAlign.licenseType"
                placeholder="Others"
              >
                <el-option
                  v-for="item in optionsLicense"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="License Number:" prop="licenseNumber">
              <el-input
                v-model.trim="formLabelAlign.licenseNumber"
                type="text"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Organization Code:" prop="organizationCode">
              <el-input
                v-model.trim="formLabelAlign.organizationCode"
                type="text"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item label="Crganization Name:" prop="corporationName">
              <el-input
                v-model.trim="formLabelAlign.corporationName"
                type="text"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item
              label=" Credentials Credentials Type:"
              prop="credentialsType"
              class="basicone"
            >
              <el-select
                v-model.trim="formLabelAlign.credentialsType"
                placeholder="Others"
              >
                <el-option
                  v-for="item in optionscrdent"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="Identification Number:"
              prop="credentialsNumber"
              class="basicone"
            >
              <el-input
                v-model.trim="formLabelAlign.credentialsNumber"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                type="text"
                maxlength="100"
              />
            </el-form-item>
            <!-- *Corporation Mobile -->
            <el-form-item label="Corporation Mobile:" prop="corporationPhone">
              <el-input
                v-model.trim="formLabelAlign.corporationPhone"
                oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                type="text"
                maxlength="100"
              />
            </el-form-item>
            <el-form-item
              label="Enterprise Type:"
              prop="enterpriseType"
              class="typeRight"
            >
              <el-select
                v-model.trim="formLabelAlign.enterpriseType"
                placeholder="Please Choose"
              >
                <el-option
                  v-for="item in optionsEnter"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="Industry Type:"
              prop="industry"
              class="typeRight"
            >
              <el-select
                v-model.trim="formLabelAlign.industry"
                placeholder="Please Choose"
              >
                <el-option
                  v-for="item in optionsindustry"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              label="Employee:"
              prop="employeeSize"
              class="typeRight"
            >
              <el-select
                v-model.trim="formLabelAlign.employeeSize"
                placeholder="Others"
              >
                <el-option
                  v-for="item in optionsEmploy"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              label="Enterprise Address:"
              prop="address"
              class="formLine-last"
            >
              <el-cascader
                v-model="optionsaddressValue"
                :props="optionsaddress"
                class="cascader"
                @change="changeOptionsaddress"
              />
              <el-input
                v-model.trim="formLabelAlign.address"
                type="text"
                placeholder="Please Enter"
                maxlength="100"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div class="panel demoWrapper">
      <div class="basic-info basic-info2">
        <div class="basic-info-top">Invoice info</div>
        <div class="">
          <el-form
            ref="formLabelAlign1"
            :model="formLabelAlign1"
            label-position="right"
            label-width="180px"
            :rules="ifTH ? rulesTH : rules2"
            :inline="true"
          >
            <div v-if="!ifTH">
              <el-form-item
                label="Invoice Register Enterprise Name:"
                prop="invoiceCompanyName"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceCompanyName"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Taxpayer Identification Number:"
                prop="invoiceTaxpayerNumber"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceTaxpayerNumber"
                  oninput="this.value=this.value.replace(/[^0-9\.]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Name of Deposit Bank:"
                prop="invoiceBankDeposit"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceBankDeposit"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Bank Account No:"
                prop="invoiceBankNumber"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceBankNumber"
                  type="text"
                  placeholder="Please Enter"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Register Address:"
                prop="invoiceRegisterAddress"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterAddress"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Register Phone No:"
                prop="invoiceRegisterPhone"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterPhone"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Invoice Register Zip Code:"
                prop="invoiceRegisterZipcode"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterZipcode"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>
            </div>
            <div v-else>
              <el-form-item
                label="Name:"
                prop="invoiceCompanyName"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceCompanyName"
                  type="text"
                  placeholder="Please Enter "
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Branch Name:"
                prop="invoiceBranchName"
                class="formLine"
              >
                <el-select
                  v-model="formLabelAlign1.invoiceBranchName"
                  placeholder="Please Choose"
                >
                  <el-option
                    v-for="item in BranchNameList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>

              <el-form-item
                label="Branch ID:"
                prop="invoiceBranchId"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceBranchId"
                  type="text"
                  placeholder="Please Enter"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="120"
                  :disabled="
                    formLabelAlign1.invoiceBranchId == '00000' ||
                      formLabelAlign1.invoiceBranchName == 3
                  "
                />
              </el-form-item>

              <el-form-item
                label="Tax ID:"
                prop="invoiceTaxpayerNumber"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceTaxpayerNumber"
                  type="text"
                  placeholder="Please Enter"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Address:"
                prop="invoiceRegisterAddress"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterAddress"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="120"
                />
              </el-form-item>

              <el-form-item
                label="Postal Code:"
                prop="invoiceRegisterZipcode"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterZipcode"
                  oninput="value=value.replace(/[^\d]/g,'')"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>

              <el-form-item
                label="Email:"
                prop="invoiceRegisterEmail"
                class="formLine"
              >
                <el-input
                  v-model="formLabelAlign1.invoiceRegisterEmail"
                  type="text"
                  placeholder="Please Enter"
                  maxlength="100"
                />
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
    </div>
    <div class="panel demoWrapper" style="padding-bottom: 67px">
      <div class="basic-info basic-info1" style="margin-bottom: 90px">
        <div class="basic-info-top">Bank Account info</div>
        <div>
          <el-form
            ref="formLabelAlign2"
            :model="formLabelAlign2"
            label-position="right"
            label-width="180px"
            :rules="rules3"
            :inline="true"
          >
            <el-form-item
              label="Enterprise Bank of Deposit Branch:"
              prop="bankDeposit"
              class="linHeight"
            >
              <el-input
                v-model.trim="formLabelAlign2.bankDeposit"
                type="text"
                maxlength="100"
              />
            </el-form-item>

            <el-form-item
              label="Enterprise Bank Account No:"
              prop="bankNumber"
              class="linHeight"
            >
              <el-input v-model.trim="formLabelAlign2.bankNumber" type="text" />
            </el-form-item>
            <!-- <el-form-item label="Unionpay No:">
              <el-input v-model.trim="formLabelAlign2.Unionpay" type="text" />
            </el-form-item> -->
          </el-form>
        </div>
      </div>
    </div>

    <div class="panel demoWrapper button-add">
      <div v-if="isShowValid" class="button-add-l">
        <!-- <span class="button-add-l-r">
          <el-radio v-model.trim="radio" label="1">InValid</el-radio>
          <el-radio v-model.trim="radio" label="2">Valid</el-radio>
        </span> -->
        <el-form>
          <el-form-item label="Audit Result" prop="radio">
            <!-- @change="agreeChange" -->
            <el-radio-group v-model.trim="formLabelAlign1.state">
              <el-radio :label="0">Valid</el-radio>
              <el-radio :label="1">Invalid</el-radio>
            </el-radio-group>
          </el-form-item>
          <div style="heigth: 500px" />
        </el-form>
      </div>
      <div class="button-add-r">
        <!-- <el-button class="submitted">Submitted Materials</el-button> -->
        <el-button type="primary" @click="apply2">
          Apply
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import 'element-ui/lib/theme-chalk/index.css';
import { modifyBusiness, getAddress } from '@/api/enterprise/enterprise';
export default {
  props: {
    obj: Object
  },
  data() {
    let _this = this;
    return {
      // 添加企业信息数据
      radio: '',
      input123: '',
      // 层级选择器
      options: [
        {
          value: 0,
          label: 'Business License'
        }
      ],
      //
      optionsaddressValue: [],
      id: process.env.VUE_APP_ADDRESS,
      //  企业地址模拟数据
      optionsaddress: {
        lazy: true,
        lazyLoad(node, resolve) {
          const { level, value } = node;
          let par = {};
          if (level === 0) {
            par.id = _this.id;
          } else {
            par.id = value;
          }
          getAddress(par).then(res => {
            let leave = null;
            if (_this.id == 10000000) {
              leave = 3;
            } else if (_this.id == 764) {
              leave = 2;
            }
            const nodes = res.data.map(item => {
              return {
                value: item.id,
                label: item.name,
                leaf: level >= leave
              };
            });
            resolve(nodes);
          });
        }
      },
      //人员规模
      optionsEmploy: [
        {
          value: '1-49',
          label: '1-49'
        },
        {
          value: '50-99',
          label: '50-99'
        },
        {
          value: '100-499',
          label: '100-499'
        },
        {
          value: '500-999',
          label: '500-999'
        },
        {
          value: '>1000',
          label: '>1000'
        }
      ],
      // 企业类型
      optionsEnter: [
        {
          value: 1,
          label: 'Public/Listed Company'
        },
        {
          value: 2,
          label: 'Private Company'
        },
        {
          value: 3,
          label: 'State-owned Company'
        },
        {
          value: 4,
          label: 'Cooperation'
        },
        {
          value: 5,
          label: 'Foundation'
        },
        {
          value: 6,
          label: 'Institution'
        },
        {
          value: 7,
          label: 'Government'
        },
        {
          value: 8,
          label: 'Others'
        }
      ],
      // 行业类型
      optionsindustry: [
        {
          value: 1,
          label: 'Banking'
        },
        {
          value: 2,
          label: 'Media & Telecommunication'
        },
        {
          value: 3,
          label: 'Transportation & Logistics'
        },
        {
          value: 4,
          label: 'Government & Public Sector'
        },
        {
          value: 5,
          label: 'Manufacturing'
        },
        {
          value: 6,
          label: 'Retail'
        },
        {
          value: 7,
          label: 'Hospitality'
        },
        {
          value: 8,
          label: 'Leisure and Entertainment '
        },
        {
          value: 9,
          label: 'Healthcare'
        },
        {
          value: 10,
          label: 'Education'
        },
        {
          value: 11,
          label: 'Finance & Real Estate'
        },
        {
          value: 12,
          label: 'Enery &Materials'
        },
        {
          value: 13,
          label: 'Primary Industry'
        },
        {
          value: 99,
          label: 'Others'
        }
      ],
      // 证照类型
      optionsLicense: [
        {
          value: 0,
          label: 'Business License'
        }
      ],
      BranchNameList: [
        {
          value: 1,
          label: 'Head Office'
        },
        {
          value: 2,
          label: 'Branch'
        },
        {
          value: 3,
          label: 'Individual'
        }
      ],
      // 法人证件类型
      optionscrdent: [
        {
          value: 0,
          label: 'ID Card'
        },
        {
          value: 1,
          label: 'Passport'
        },
        {
          value: 2,
          label: 'Other'
        }
      ],

      //  企业地址模拟数据

      rules1: {
        enterpriseName: [
          {
            required: true,
            message: 'Enterprise Name is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        enterpriseType: [
          {
            required: true,
            message: 'Enterprise Type is empty',
            trigger: 'change'
          }
        ],
        enterpriseEmail: [
          {
            required: true,
            message: 'Enterprise Email is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' },
          {
            validator: function(rule, value, callback) {
              if (
                /^[A-Za-z0-9-_\.]{1,64}@[a-z0-9-]{1,256}(\.[a-z]{2,6}){1,2}$/i.test(value) ==
                false
              ) {
                callback(new Error('Mailbox format error'));
              } else {
                callback();
              }
            },
            trigger: 'blur'
          }
        ],
        enterprisePhone: [
          {
            required: true,
            message: 'Enterprise Phone is empty',
            trigger: 'blur'
          },
          {
            max: 100,
            trigger: 'blur',
            validator: function(rule, value, callback) {
              if (/^[0-9]*$/.test(value) == false) {
                callback(new Error('Mailbox format error'));
              } else {
                callback();
              }
            }
          }
        ],
        licensetype: [
          {
            required: true,
            message: 'Licensetype is empty',
            trigger: 'change'
          }
        ],
        licenseNumber: [
          {
            required: true,
            message: 'License Number is empty',
            trigger: 'blur',
            max: 100
          }
        ],
        CorporationMobile: [
          {
            required: true,
            message: 'Licensetype is empty',
            trigger: 'change'
          }
        ],
        organizationCode: [
          {
            required: true,
            message: 'Organization Code is empty ',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        corporationName: [
          {
            required: true,
            message: 'Legal Person Name is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        corporationPhone: [
          {
            required: true,
            message: 'Legal Person Mobile is empty',
            trigger: 'blur'
          },
          { max: 50, trigger: 'blur' }
        ],
        credentialsType: [
          {
            required: true,
            message: 'Credentials Type is empty',
            trigger: 'blur'
          }
        ],
        credentialsNumber: [
          {
            required: true,
            message: 'Credentials Number is empty',
            trigger: 'blur'
          },
          {
            max: 100,
            trigger: 'blur',
            validator: function(rule, value, callback) {
              if (/^[0-9]*$/.test(value) == false) {
                callback(new Error('Mailbox format error'));
              } else {
                callback();
              }
            }
          }
        ],
        industry: [
          {
            required: true,
            message: 'Industry Type is empty',
            trigger: 'blur'
          }
        ],
        employeeSize: [
          { required: true, message: 'Employee is empty', trigger: 'blur' }
        ],
        address: [
          {
            required: true,
            message: 'Enterprise Address is empty',
            trigger: 'blur'
          }
        ]
      },
      rulesTH: {
        invoiceCompanyName: [
          {
            required: true,
            message: 'Invoice Register Enterprise Name is empty',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceBranchName: [
          {
            required: true,
            message: 'Please Choose Invoice Branch Name',
            trigger: 'change'
          }
        ],
        invoiceBranchId: [
          {
            required: true,
            message: 'Place Enter Invoice Branch ID',
            trigger: 'blur'
          },
          { max: 120, trigger: 'blur' }
        ],
        invoiceTaxpayerNumber: [
          {
            required: true,
            message: 'Place Enter Taxpayer Number',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],

        invoiceRegisterAddress: [
          {
            required: true,
            message: 'Invoice Register Address is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceRegisterZipcode: [
          {
            required: true,
            message: 'Invoice Register Zip Code is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceRegisterEmail: [
          {
            required: true,
            message: 'Invoice Register Email is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ]
      },
      rules2: {
        invoiceCompanyName: [
          {
            required: true,
            message: 'Invoice Register Enterprise ID is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceTaxpayerNumber: [
          {
            required: true,
            message: 'Taxpayer Identification Number is empty',
            trigger: 'blur',
            max: 100
          }
        ],
        invoiceBankDeposit: [
          {
            required: true,
            message: 'Name of Deposit Bank is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceBankNumber: [
          {
            required: true,
            message: 'Invoice Bank Account No is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],

        invoiceRegisterAddress: [
          {
            required: true,
            message: 'Invoice Register Address is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceRegisterPhone: [
          {
            required: true,
            message: 'Invoice Register Phone No is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        invoiceRegisterZipcode: [
          {
            required: true,
            message: 'Invoice Register Zip Code is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ]
      },
      rules3: {
        bankDeposit: [
          {
            required: true,
            message: 'Enterprise Bank of Deposit Branch is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ],
        bankNumber: [
          {
            required: true,
            message: 'Enterprise Bank Account No is empty',
            trigger: 'blur'
          },
          { max: 100, trigger: 'blur' }
        ]
      },
      value: '',
      labelPosition: 'left',
      // 银行信息数据
      formLabelAlign: {
        licenseType: '0'
      },
      formLabelAlign1: {},
      formLabelAlign2: {},
      newAarr: {},
      modifyBank: 0,
      modifyInvoice: 0,
      modifyEnterprise: 0,
      ifTH: false
    };
  },
  computed:{
    isShowValid(){
      return this.formLabelAlign.bankInfoAuditState==1 && this.formLabelAlign.invoiceInfoAuditState==1 && this.formLabelAlign.baseInfoAuditState==1
    }
  },
  // 更新数据
  watch: {
    'formLabelAlign1.invoiceBranchName': function(val) {
      if (this.formLabelAlign1.invoiceBranchName == 1) {
        this.formLabelAlign1.invoiceBranchId = '00000';
      } else if (this.formLabelAlign1.invoiceBranchName == 3) {
        this.formLabelAlign1.invoiceBranchId = '0';
      } else {
        this.formLabelAlign1.invoiceBranchId = '';
      }
    },
    // radio(val){
    //   if((this.formLabelAlign.bankInfoAuditState!=1 || this.formLabelAlign.invoiceInfoAuditState!=1 || this.formLabelAlign.baseInfoAuditState!=1) && this.state==0 ){
    //     this.$message({message:'The enterprise  is invalid and cannot be audited'})
    //     this.state=0
    //   }
    // }
  },
  created() {
    if (process.env.VUE_APP_BASE_API == '/apiid') {
      this.ifTH = false;
    } else if (process.env.VUE_APP_BASE_API == '/apith') {
      this.ifTH = true;
    }
    this.info();
  },
  methods: {
    changeOptionsaddress(val) {
      //  province: 1, // 公司地址-一级地址ID
      //   city: 2, //'公司地址-二级地址ID',
      //   district: 3, // 公司地址-三级地址ID',
      //   town: 4, //'公司地址-四级地址ID',
      if (val.length == 0) {
        this.formLabelAlign.province = 0;
        this.formLabelAlign.city = 0;
        this.formLabelAlign.district = 0;
        this.formLabelAlign.town = 0;
      } else {
        if (val && val.length === 4) {
          this.formLabelAlign.province = val[0];
          this.formLabelAlign.city = val[1];
          this.formLabelAlign.district = val[2];
          this.formLabelAlign.town = val[3];
        } else if (val && val.length === 3) {
          this.formLabelAlign.province = val[0];
          this.formLabelAlign.city = val[1];
          this.formLabelAlign.district = val[2];
        } else if (val && val.length === 2) {
          this.formLabelAlign.province = val[0];
          this.formLabelAlign.city = val[1];
        } else if (val && val.length === 1) {
          this.formLabelAlign.province = val[0];
        }
      }
    },
    //  应用提交材料

    apply2() {
      if (
        this.newAarr.enterpriseName !== this.formLabelAlign.enterpriseName ||
        this.newAarr.enterpriseEmail !== this.formLabelAlign.enterpriseEmail ||
        this.newAarr.enterprisePhone !== this.formLabelAlign.enterprisePhone ||
        this.newAarr.licenseType !== this.formLabelAlign.licenseType ||
        this.newAarr.licenseNumber !== this.formLabelAlign.licenseNumber ||
        this.newAarr.organizationCode !==
          this.formLabelAlign.organizationCode ||
        this.newAarr.corporationName !== this.formLabelAlign.corporationName ||
        this.newAarr.corporationPhone !==
          this.formLabelAlign.corporationPhone ||
        this.newAarr.credentialsType !== this.formLabelAlign.credentialsType ||
        this.newAarr.credentialsNumber !==
          this.formLabelAlign.credentialsNumber ||
        this.newAarr.industry !== this.formLabelAlign.industry ||
        this.newAarr.employeeSize !== this.formLabelAlign.employeeSize ||
        this.newAarr.enterpriseType !== this.formLabelAlign.enterpriseType
      ) {
        this.modifyEnterprise = 1;
      }
      if (
        this.newAarr.invoiceCompanyName !==
          this.formLabelAlign2.invoiceCompanyName ||
        this.newAarr.invoiceTaxpayerNumber !==
          this.formLabelAlign2.invoiceTaxpayerNumber ||
        this.newAarr.invoiceBankDeposit !==
          this.formLabelAlign2.invoiceBankDeposit ||
        this.newAarr.invoiceBankNumber !==
          this.formLabelAlign2.invoiceBankNumber ||
        this.newAarr.invoiceRegisterAddress !==
          this.formLabelAlign2.invoiceRegisterAddress ||
        this.newAarr.invoiceRegisterPhone !==
          this.formLabelAlign.invoiceRegisterPhone ||
        this.newAarr.invoiceRegisterZipcode !==
          this.formLabelAlign.invoiceRegisterZipcode
      ) {
        this.modifyInvoice = 1;
      }
      if (
        this.newAarr.bankDeposit !== this.formLabelAlign2.bankDeposit ||
        this.newAarr.bankNumber !== this.formLabelAlign2.bankNumber
      ) {
        this.modifyBank = 1;
      }

      const params = {
        id: this.formLabelAlign.id,
        enterpriseName: this.formLabelAlign.enterpriseName,
        enterpriseType: this.formLabelAlign.enterpriseType,
        enterpriseEmail: this.formLabelAlign.enterpriseEmail,
        enterprisePhone: this.formLabelAlign.enterprisePhone,
        licenseType: this.formLabelAlign.licenseType,
        licenseNumber: this.formLabelAlign.licenseNumber,
        organizationCode: this.formLabelAlign.organizationCode,
        corporationName: this.formLabelAlign.corporationName,
        corporationPhone: this.formLabelAlign.corporationPhone,
        credentialsType: this.formLabelAlign.credentialsType,
        credentialsNumber: this.formLabelAlign.credentialsNumber,
        industry: this.formLabelAlign.industry,
        employeeSize: this.formLabelAlign.employeeSize,

        invoiceCompanyName: this.formLabelAlign1.invoiceCompanyName,

        invoiceTaxpayerNumber: this.formLabelAlign1.invoiceTaxpayerNumber,
        invoiceBankDeposit: this.formLabelAlign1.invoiceBankDeposit,

        invoiceBankNumber: this.formLabelAlign1.invoiceBankNumber,
        invoiceRegisterAddress: this.formLabelAlign1.invoiceRegisterAddress,

        invoiceRegisterPhone: this.formLabelAlign1.invoiceRegisterPhone,
        invoiceRegisterZipcode: this.formLabelAlign1.invoiceRegisterZipcode,

        bankDeposit: this.formLabelAlign2.bankDeposit,
        bankNumber: this.formLabelAlign2.bankNumber,
        bankInfoAuditState: this.obj.bankInfoAuditState,
        baseInfoAuditState: this.obj.baseInfoAuditState,
        invoiceInfoAuditState: this.obj.invoiceInfoAuditState,
        state: this.formLabelAlign1.state,
        dataVersion: this.obj.dataVersion,

        creator: this.formLabelAlign2.creator,
        modifyBank: this.modifyBank,
        modifyInvoice: this.modifyInvoice,
        modifyEnterprise: this.modifyEnterprise,

        province: this.optionsaddressValue[0],
        city: this.optionsaddressValue[1],
        district: this.optionsaddressValue[2],
        town: this.optionsaddressValue[3],
        address: this.formLabelAlign.address
      };
      const p1 = new Promise((resolve, reject) => {
        this.$refs['formLabelAlign'].validate(valid => {
          if (valid) resolve();
        });
      });

      const p2 = new Promise((resolve, reject) => {
        this.$refs['formLabelAlign1'].validate(valid => {
          if (valid) resolve();
        });
      });

      const p3 = new Promise((resolve, reject) => {
        this.$refs['formLabelAlign2'].validate(valid => {
          if (valid) resolve();
        });
      });
      Promise.all([p1, p2, p3]).then(() => {
        const params1 = {
          id: this.formLabelAlign.id,
          enterpriseName: this.formLabelAlign.enterpriseName,
          enterpriseType: this.formLabelAlign.enterpriseType,
          enterpriseEmail: this.formLabelAlign.enterpriseEmail,
          enterprisePhone: this.formLabelAlign.enterprisePhone,
          licenseType: this.formLabelAlign.licenseType,
          licenseNumber: this.formLabelAlign.licenseNumber,
          organizationCode: this.formLabelAlign.organizationCode,
          corporationName: this.formLabelAlign.corporationName,
          corporationPhone: this.formLabelAlign.corporationPhone,
          credentialsType: this.formLabelAlign.credentialsType,
          credentialsNumber: this.formLabelAlign.credentialsNumber,
          industry: this.formLabelAlign.industry,
          employeeSize: this.formLabelAlign.employeeSize,

          invoiceCompanyName: this.formLabelAlign1.invoiceCompanyName,

          invoiceTaxpayerNumber: this.formLabelAlign1.invoiceTaxpayerNumber,
          invoiceBankDeposit: this.formLabelAlign1.invoiceBankDeposit,

          invoiceBankNumber: this.formLabelAlign1.invoiceBankNumber,
          invoiceRegisterAddress: this.formLabelAlign1.invoiceRegisterAddress,

          invoiceRegisterPhone: this.formLabelAlign1.invoiceRegisterPhone,
          invoiceRegisterZipcode: this.formLabelAlign1.invoiceRegisterZipcode,

          invoiceRegisterEmail: this.formLabelAlign1.invoiceRegisterEmail,
          invoiceBranchName: this.formLabelAlign1.invoiceBranchName + '',
          invoiceBranchId: this.formLabelAlign1.invoiceBranchId,

          bankDeposit: this.formLabelAlign2.bankDeposit,
          bankNumber: this.formLabelAlign2.bankNumber,
          bankInfoAuditState: this.obj.bankInfoAuditState,
          baseInfoAuditState: this.obj.baseInfoAuditState,
          invoiceInfoAuditState: this.obj.invoiceInfoAuditState,
          state: this.formLabelAlign1.state,
          dataVersion: this.obj.dataVersion,

          creator: this.formLabelAlign2.creator,
          modifyBank: this.modifyBank,
          modifyInvoice: this.modifyInvoice,
          modifyEnterprise: this.modifyEnterprise,

         
          address: this.formLabelAlign.address
        };
        let linkageType = {}
        if (this.ifTH) { // 泰国
          linkageType = {
            province: this.optionsaddressValue[0],
            city: this.optionsaddressValue[1],
            district: this.optionsaddressValue[2]
          }
        } else {// 印尼
          linkageType = {
            province: this.optionsaddressValue[0],
            city: this.optionsaddressValue[1],
            district: this.optionsaddressValue[2],
            town: this.optionsaddressValue[3]
          }
        }
        const params = Object.assign(params1, linkageType);
        modifyBusiness(params).then(res => {
          if (res.code == 200) {
            this.$message.success('Operation succeeded');
            this.$emit('get-quren', 1);
          }else{
            this.$message({type:'error',message:res.msg});
          }
        });
      });
    },

    // 页面刚进来执行
    info() {
      this.formLabelAlign = JSON.parse(JSON.stringify(this.obj));
      this.formLabelAlign1 = this.obj;
      this.formLabelAlign2 = this.obj;
      this.formLabelAlign1.invoiceBranchName = +this.formLabelAlign1.invoiceBranchName;
      this.optionsaddressValue = this.ifTH ? [
        this.obj.province,
        this.obj.city,
        this.obj.district,
      ] : [
        this.obj.province,
        this.obj.city,
        this.obj.district,
        this.obj.town
      ];
      this.newAarr = Object.assign(this.obj);
    }
  }
};
</script>
<style lang="less" scoped>
.businessList {
  .demoWrapper {
    /deep/.el-input__inner {
      width: 220px;
      height: 32px;
      line-height: 32px;
    }

    ::v-deep .el-form-item__content {
      width: 220px;
    }
    .basic-info {
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 11px;
      border-radius: 8px;
      .basic-info-top {
        height: 67px;
        line-height: 67px;
        font-size: 20px;
        font-family: Arial;
        font-weight: normal;
        color: rgba(36, 37, 38, 1);
      }
      .basic-info-con {
        overflow: hidden;
        overflow-x: visible;
        padding-left: 27px;
        padding-right: 27px;
        .el-row {
          width: 100%;
        }
      }
      .basic-foot {
        height: 80px;
        padding-top: 39px;
      }
    }
    .invoice-info {
      margin-top: 20px;
    }
  }
  .button-add {
    position: fixed;
    bottom: 0;
    height: 140px;
    width: 100%;
    padding-top: 20px;
    box-shadow: 3px 5px 9px #000;
    .button-add-l {
      padding-left: 20px;
      .button-add-l-r {
        padding-left: 15px;
      }
    }
    .button-add-r {
      position: absolute;
      bottom: 60px;
      left: 68%;
      .submitted {
        width: 180px;
        height: 40px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
      }
    }
  }
  .el-form-item {
    margin-bottom: 30px;
  }
  .basicone {
    /deep/.el-form-item__label {
      line-height: 20px !important;
    }
  }
  .linHeight {
    /deep/.el-form-item__label {
      line-height: 20px !important;
    }
  }
  ::v-deep .formLine-last {
    .el-form-item__label {
      line-height: 32px !important;
      text-align: right !important;
    }
    .el-form-item__content {
      width: 900px;
      .el-cascader {
        margin-left: 0;
        .el-input {
          margin-left: 0;
          width: 390px;
        }
      }
      .el-input {
        width: 210px;
        margin-left: 40px;
      }
    }
  }
  /deep/.cascader {
    .el-input__inner {
      width: 400px;
    }
    width: 390px;
    height: 32px;
  }
  ::v-deep .el-radio-group {
    .el-radio__label {
      color: #242526;
    }
    .is-checked {
      .el-radio__inner {
        background-color: #2262ff;
      }
      .el-radio__label {
        color: #242526;
      }
    }
  }
}
</style>
<style lang="less">
.businessList {
  .Enterprise {
    margin-left: 50px !important;
  }
}
</style>
